import * as React from 'react'
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import '@fontsource/maven-pro/400.css'
import '@fontsource/maven-pro/500.css'
import '@fontsource/maven-pro/600.css'
import '@fontsource/maven-pro/700.css'
import '@fontsource/maven-pro/800.css'
import '@fontsource/maven-pro/900.css'
import './src/styles/default.css';

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
)
